






















































































































































































































import { Component, Ref, Vue } from "vue-property-decorator";
import { payment_data } from "./payment_data";
import {
  DxAdapter,
  DxEmailRule,
  DxRequiredRule,
  DxNumericRule,
} from "devextreme-vue/validator";
import { DxValidationGroup } from "devextreme-vue/validation-group";
import { DxValidationSummary } from "devextreme-vue/validation-summary";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";
import "@/assets/css/dx.material.my-medical-diet-theme.css";
import "devextreme/dist/css/dx.common.css";
import "@/assets/scss/main.scss";
import DxButton from "devextreme-vue/button";
import { cupon_descuentoDto } from "@/shared/dtos/payments/cupon_descuentoDto";
@Component({
  components: {
    PayPalCheckoutVue: () => import("./components/PaypalCheckoutTs.vue"),
    DxTextBox: () => import("devextreme-vue/text-box"),
    DxValidationGroup,
    DxValidator: () => import("devextreme-vue/validator"),
    DxAdapter,
    DxEmailRule,
    DxRequiredRule,
    DxValidationSummary,
    DxNumericRule,
    DxButton,
  },
})
export default class Paypal_payments extends Vue {
  /*private paypal = {
    sandbox:
      "AeIr9_c2anQpb06c4Hbd19GcoFTdpQm2L3rxEn2g2eKbB58PvfPJj-vsOYeJbJjIkXD6szPDnNJybMrv",
    production:
      "AWBb4yJZQbvUMje9Ke9eDoBuzvCWwXnuRH3-9AodqDPwH4n6fNyoj8IJswwcB5pkwWiIjBkX7ihJVFgo",
  };
  
  470
  820
  */

  public data_payment: payment_data = new payment_data({
    producto: "",
    precio: 0,
  });
  public unidades_productos: number = 1;
  public cupon_descuento: cupon_descuentoDto = new cupon_descuentoDto({
    cupon: "",
  });
  public datos_validos: boolean = false;
  public show_finish: boolean = false;

  public subscripcion_paypal_id = "P-67445870G5985833MMVO3L6Q";
  public plan_id_20 = "P-67445870G5985833MMVO3L6Q";
  public plan_id_15 = "P-3N815927M4996201LMVO6RYI";
  public plan_id_700 = "P-0NK046320P2492930MVPS52I";

  @Ref("customValidator") readonly validacion!: DxValidationGroup;
  //update:items
  beforeCreate() {}
  mount() {}
  async created() {
    const esMessages = await import("devextreme/localization/messages/es.json");
    const { locale, loadMessages } = await import("devextreme/localization");

    loadMessages(esMessages);

    locale("es");

    ssmHttpService
      .get(API.MymedicaldietIntegrations + "/licencia/" + this.$route.params.id)
      .then((x: any) => {
        this.data_payment.producto = x.nombre_articulo;
        this.data_payment.precio = x.precio;
        this.cupon_descuento.id_licencia = x.id;
        if (x.precio >= 23) {
          this.subscripcion_paypal_id = this.plan_id_700;
        }
      });
    if (this.$route.query.paymentCompleted !== undefined) {
      if (this.$route.query.paymentCompleted === "true") {
        this.show_finish = true;
      }
    }
  }

  public get parameter_change() {
    ssmHttpService
      .get(API.MymedicaldietIntegrations + "/licencia/" + this.$route.params.id)
      .then((x: any) => {
        this.data_payment.producto = x.nombre_articulo;
        this.data_payment.precio = x.precio;
      });
    if (this.$route.query.paymentCompleted !== undefined) {
      if (this.$route.query.paymentCompleted === "true") {
        this.show_finish = true;
      }
    }
    return ".";
  }

  public validar_los_datos() {
    this.datos_validos = this.validacion.instance!.validate().isValid!;
  }

  public goToLogin() {
    //this.$router.push({ name: RouterNames.Login });
    //document.open("https://app.mymedicaldiet.es/#/login");
    window.parent.postMessage("HELLO_PARENT", "https://mymedicaldiet.es");
  }

  public change_input(event: any, id: number) {
    ssmHttpService.post(
      API.MymedicaldietIntegrations + "/paso_finalizar_compra/",
      JSON.stringify(this.data_payment),
      false
    );
    if (id === 0) {
      ssmHttpService
        .post(
          API.MymedicaldietIntegrations + "/validar_cupon/",
          JSON.stringify(this.cupon_descuento),
          true,
          false,
          true
        )
        .then((x: any) => {
          this.unidades_productos = x.unidades;
          this.data_payment.precio = x.precio;

          if (x.precio <= 15) {
            this.subscripcion_paypal_id = this.plan_id_15;
          } else if (x.precio <= 23) {
            this.subscripcion_paypal_id = this.plan_id_20;
          } else {
            this.subscripcion_paypal_id = this.plan_id_700;
          }
          console.log(this.subscripcion_paypal_id);
        })
        .catch((e: any) => {
          //await this.getLicencia();
        });
    } else if (id === 5) {
      this.validar_los_datos();
    }
  }

  paymentCompleted(data: any) {
    ssmHttpService
      .post(
        API.MymedicaldietIntegrations + "/pago-suscripcion/",
        JSON.stringify({
          customer: this.data_payment,
          payment: data,
          licencia: "60",
        })
      )
      .then(() => {
        this.show_finish = true;
        this.$route.query.paymentCompleted = "true";
      });
  }

  createOrder() {
    //@ts-ignore
    paypal
      .Buttons({
        style: {
          shape: "pill",
          color: "gold",
          layout: "vertical",
          label: "paypal",
        },
        createSubscription: (data: any, actions: any) => {
          return actions.subscription.create({
            /* Creates the subscription */
            //plan_id: this.subscripcion_paypal_id,
            plan_id: "P-7SH71189NY516943KMVXM5TI",
            quantity: 60, // The quantity of the product for a subscription
          });
        },
        onApprove: (data: any, actions: any) => {
          //alert(data.subscriptionID); // You can add optional success message for the subscriber here
          //console.log({ data: data, actions: actions })
          this.paymentCompleted({ data: data, actions: actions });

          /*
            {actions.order.capture().then((details: any) => {
            //this.$emit("payment-completed", details);
            this.paymentCompleted({ data: data, actions: details });
            // This function shows a transaction success message to your buyer.
          });*/
        },
      })
      .render("#paypal-button-container"); // Renders the PayPal button
  }
  // Example function to show a result to the user. Your site's UI library can be used instead.
  resultMessage(message: string) {
    const container: Element | null = document.querySelector("#result-message");
    container!.innerHTML = message;
  }
}
